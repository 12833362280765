import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import ParseContent from 'components/shared/ParseContent'
import TitleDefault from 'components/elements/Text/TitleDefault'
import ButtonSecondaryLight from 'components/elements/Buttons/ButtonSecondaryLight'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'
import NavLink from 'components/shared/NavLink'

interface LatestProjectsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_LatestProjects
}

const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.color.background};
`

const LatestProjects: React.FC<LatestProjectsProps> = ({ fields }) => (
  <Wrapper className="mb-5 p-lg-4">
    <div className="container py-5">
      <div className="row">
        <TitleDefault isCentered>
          <ParseContent content={fields.title} />
        </TitleDefault>
      </div>

      <Blog fields={fields}>123</Blog>

      <div className="d-flex justify-content-center">
        <ButtonSecondaryLight to={fields.link?.url || '/'}>
          {fields.link?.title}
        </ButtonSecondaryLight>
      </div>
    </div>
  </Wrapper>
)

interface BlogProps {
  // eslint-disable-next-line
  fields: any
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpProject,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.latestProjectsQueryQuery>(graphql`
    query latestProjectsQuery {
      allWpProject(sort: { order: DESC, fields: date }) {
        edges {
          node {
            id
            title
            uri
            projectdetail {
              mainimage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 1400)
                  }
                }
              }
              status
              fieldGroupName
            }
          }
        }
      }
    }
  `)

  const posts: any = allWpProject.edges
  const filteredPosts: Array<any> = []

  if (fields.styletype === 'inDevelopment') {
    posts.forEach((post: any) => {
      if (post.node.projectdetail.status === 'inDevelopment') {
        filteredPosts.push(post)
      }
    })
  }

  if (fields.styletype === 'realised') {
    posts.forEach((post: any) => {
      if (post.node.projectdetail.status === 'realised') {
        filteredPosts.push(post)
      }
    })
  }

  return (
    <section>
      <BlogBink
        posts={filteredPosts as BlogBinkPosts}
        id="latest-Projects"
        limit={Number(fields.amount)}
      >
        <div className="py-5">
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row">
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <div
              key={post.node.id}
              className="col-md-4 d-flex flex-grow- 1mt-5"
            >
              <BlogGridPost node={node} blogFields={fields} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const Project = styled.div`
  & h5 {
    color: ${({ theme }) => theme.color.light};
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
`

const Image = styled(Plaatjie)`
  min-height: 285px;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.75;
  }
  @media (max-width: 991px) {
    max-height: 200px;
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: {
    id: string
    title: string
    uri: string
    // eslint-disable-next-line
    projectdetail: GatsbyTypes.WpProject_Projectdetail
  }
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => (
  <Project className="mb-5 mb-md-0">
    <NavLink to={node.uri}>
      <Image
        image={node.projectdetail.mainimage}
        alt={node.title}
        className="h-75"
      />
    </NavLink>
    <div className="h-25 pt-2">
      <h5 className="text-uppercase">{node.title}</h5>
    </div>
  </Project>
)

export default LatestProjects
